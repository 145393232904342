import "./contentViewer.styles.css";
import { Suspense } from "react";
import "ckeditor5/ckeditor5.css";

type ContentViewerProps = {
  textoHTML: string;
};

export const ContentViewer = ({ textoHTML }: ContentViewerProps) => {
  return (
    <Suspense fallback={<div>Cargando...</div>}>
      <div className="ck ck-content ck-editor__editable ck-editor__editable_inline item-componente">
        <div dangerouslySetInnerHTML={{ __html: textoHTML }} />
      </div>
    </Suspense>
  );
};
