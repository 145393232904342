import { v4 as uuidv4 } from "uuid";
// import { useAuthContext } from "../../common/auth/AuthContext";
// import useHttp from "../../common/useHttp";
// import { CursoModel, CursoPayload } from "./PlantillaCursoInterface";
// import { CursoModel, CursoPayload } from "../interface";

// import plantillaCursoFake from "../../../data/PlantillaCurso";
import { CursoModel, CursoPayload } from "../interface";
import { coursesFake } from "../../../data/courses";
import useHttp from "../../../shared/hooks/useHttp";
import { printLog } from "../../../shared/utils";
import { Alerts } from "../../../shared/hooks/useAlert";
import axios from "axios";

export function useGetCourses(): () => Promise<any> {
  // return async () => [...plantillaCursoFake];
  const http = useHttp();
  return async () => http.get("/plantilla_curso", {});

  return async () => {
    const _cursos = localStorage.getItem("cursos");
    printLog("Cursos obtenidos de localstorage > ", _cursos);
    let cursos;
    if (!_cursos) {
      localStorage.setItem("cursos", JSON.stringify(coursesFake));
      cursos = coursesFake;
    } else {
      cursos = JSON.parse(_cursos);
    }
    return cursos;
  };
}

export function useGetCourseById(): (cursoId: string) => Promise<any> {
  const http = useHttp();
  return async (cursoId: string) => http.get(`/plantilla_curso/${cursoId}`, {});

  return async (cursoId: string) => {
    const _cursos = localStorage.getItem("cursos");
    printLog("Cursos obtenidos de localstorage > ", _cursos);
    const cursos = JSON.parse(_cursos!);
    return cursos.find((item: any) => item.id === cursoId);
  };
  // [...plantillaCursoFake].find((item) => item.id === cursoId);
}

export function useCreateCourse(): (
  payload: CursoModel,
  adjuntosContenido: any
) => Promise<any> {
  const http = useHttp();
  // const authContext = useAuthContext();
  return async (payload: CursoModel, adjuntosContenido: any) => {
    const formData = _getCursoPayload(payload, adjuntosContenido);
    printLog("[create:curso] form data >> ", formData);
    //formData.id = uuidv4();
    const _cursos = localStorage.getItem("cursos");
    printLog("Cursos obtenidos de localstorage > ", _cursos);
    const cursos = _cursos ? JSON.parse(_cursos) : [];
    cursos.push(formData);
    printLog("Cursos >>> ", cursos);
    localStorage.setItem("cursos", JSON.stringify(cursos));
    //return formData;
    try {
      return await http.post("/plantilla_curso", {
        payload: formData,
        //auth: authContext?.user?.token ?? undefined,
        withFiles: true,
      });
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        printLog("Error al crear el curso >> ", error);
        Alerts.showError({
          description: error.message || "Ocurrió un error desconocido",
        });
      } else {
        printLog("Error desconocido >> ", error);
        Alerts.showError({
          description: "Ocurrió un error desconocido",
        });
      }
    }
  };
}

export function useUpdateCourse(): (
  payload: CursoModel,
  plantillaCursoId: string,
  adjuntosContenido: any
) => Promise<any> {
  const http = useHttp();
  // const authContext = useAuthContext();
  return async (
    payload: CursoModel,
    plantillaCursoId: string,
    adjuntosContenido: any
  ) => {
    const formData = _getCursoPayload(payload, adjuntosContenido);
    printLog("[update:curso] form data >> ", formData);
    const _cursos = localStorage.getItem("cursos");
    printLog("Cursos obtenidos de localstorage > ", _cursos);
    const cursos = _cursos ? JSON.parse(_cursos) : [];
    const idxCurso = cursos.findIndex(
      (item: any) => item.id === plantillaCursoId
    );
    printLog("indice de curso >> ", idxCurso);
    cursos[idxCurso] = { ...formData };
    printLog("Cursos actualizados >>> ", cursos);
    localStorage.setItem("cursos", JSON.stringify(cursos));
    //return formData;

    try {
      return await http.put(`/plantilla_curso/${plantillaCursoId}`, {
        payload: formData,
        //auth: authContext?.user?.token ?? undefined,
        withFiles: true,
      });
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        printLog("Error al crear el curso >> ", error);
        Alerts.showError({
          description: error.message || "Ocurrió un error desconocido",
        });
      } else {
        printLog("Error desconocido >> ", error);
        Alerts.showError({
          description: "Ocurrió un error desconocido",
        });
      }
    }
  };
}

export function useDeleteCourse(): (cursoPlantillaId: number) => Promise<any> {
  const http = useHttp();
  return async (cursoPlantillaId: number) => {
    try {
      const _cursos = localStorage.getItem("cursos");
      printLog("Cursos obtenidos de localstorage > ", _cursos);
      const cursos = _cursos ? JSON.parse(_cursos) : [];
      const idxCurso = cursos.findIndex(
        (item: any) => item.id === cursoPlantillaId
      );
      printLog("indice de curso >> ", idxCurso);
      cursos.splice(idxCurso, 1);
      localStorage.setItem("cursos", JSON.stringify(cursos));
      return await http.delete(`/plantilla_curso/${cursoPlantillaId}`, {});
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        printLog("Error al eliminar el curso >> ", error);
        Alerts.showError({
          description: error.message || "Ocurrió un error desconocido",
        });
      } else {
        printLog("Error desconocido >> ", error);
        Alerts.showError({
          description: (error as any).message || "Ocurrió un error desconocido",
        });
      }
    }
  };
}

export function useChangeCourseStatus(): (
  cursoPlantillaId: number,
  active: boolean
) => Promise<any> {
  const http = useHttp();
  return async (cursoPlantillaId: number, active: boolean) => {
    const route = active === true ? "desactivar" : "activar";
    try{
      return await http.put(`/plantilla_curso/${cursoPlantillaId}/${route}`, {
        payload: { activo: active },
      });
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        printLog("Error al cambiar el estado del curso >> ", error);
        Alerts.showError({
          description: error.message || "Ocurrió un error desconocido",
        });
      } else {
        printLog("Error desconocido >> ", error);
        Alerts.showError({
          description: "Ocurrió un error desconocido",
        });
      }
    }
  };
}

type getUnidadCursoParticipanteParams = {
  cursoId: string;
  unidadId: string;
};

// export function useGetUnidadCurso(): (
//   params: getUnidadCursoParticipanteParams
// ) => Promise<any> {
//   // const http = useHttp();
//   return async (params: getUnidadCursoParticipanteParams) => {
//     const _cursos = localStorage.getItem("cursos");
//     printLog("Cursos obtenidos de localstorage > ", _cursos);
//     const cursos = JSON.parse(_cursos!);
//     const _curso = cursos.find((item: any) => item.id === params.cursoId);

//     const _unidad =

//     const unidad = (await http.get(
//       `/participante/curso_participante/${params.cursoParticipanteId}/unidad/${params.unidadId}`,
//       {}
//     )) as UnidadCursoParticipante;
//     return unidad;
//   };
// }

// Private Functions
function _getCursoPayload(payload: CursoModel, adjuntosContenido: any) {
  const _payload = { ...payload };
  const jsonPayload: CursoPayload = {
    ...payload,
    imagenCurso: undefined,
    urlImagen: null,
  };
  printLog("payload ::: ", _payload);
  printLog(
    "_payload.unidades ::: ",
    typeof _payload.unidades,
    _payload.unidades
  );
  const arrayData = new FormData();
  if (_payload.imagenCurso) {
    printLog(">>>>>>>>> _payload.imagenCurso :: ", _payload.imagenCurso);
    const ID = uuidv4();
    printLog("ID :: ", ID);
    const _imagenCurso = _payload.imagenCurso;
    printLog("ID :: ", ID);
    jsonPayload.imagenCurso = {
      tempId: ID,
      nombreArchivo: _imagenCurso.name,
    };
    arrayData.append("adjuntos", _imagenCurso, ID);
    printLog("imageCurso :: ", _imagenCurso);
  }

  if (adjuntosContenido) {
    printLog("El contenido dle curso tiene adjuntos :: ", adjuntosContenido);
    for (const item of Object.keys(adjuntosContenido)) {
      arrayData.append("adjuntos", adjuntosContenido[item], item);
    }
  }

  printLog("data stringify :: ", JSON.stringify(jsonPayload));

  arrayData.append("data", JSON.stringify(jsonPayload));
  return arrayData;
  //return jsonPayload;
}
