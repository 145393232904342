import theme from "../../themes";
import { Box, Grid, Typography, styled } from "@mui/material";
import bgFooter from "../../assets/bg_footer.jpg";
import logo from "../../assets/footer_isotipo_nuevo.png";
import marcaSwe from "../../assets/foot_sue.png";
import solidarLogo from "../../assets/solidar_logo_nuevo.jpg";
import footerLogo from "../../assets/Color.jpg";

const StyledBox = styled(Box)(() => ({
  margin: 0,
  padding: 0,
  width: "100%",
  height: '70vh',
  backgroundImage: `url(${bgFooter})`,
  backgroundSize: "cover",
  // backgroundAttachment: 'fixed',
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
}));

const StyledImg = styled("img")(() => ({
  height: "100%",
  maxHeight: "100%",
  maxWidth: "100%",
  objectFit: "contain",
  width: 310,
  [theme.breakpoints.up("xs")]: {
    width: 110,
  },
  [theme.breakpoints.up("sm")]: {
    width: 210,
  },
  [theme.breakpoints.up("md")]: {
    width: 310,
  },
}));

const Footer = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ width: "100%", margin: "0 0 0 0", padding: 0 }}
    >
      <StyledBox display="flex" justifyContent="center" alignItems="center" sx={{ pt: 4 }}>
        <Box
          display="flex"
          flexDirection="column"
          sx={{ width: { xs: "100%", md: "75%" }, height: "100%" }}
        >
          <Box display="flex" justifyContent="center" mt={"7em"}>

          </Box>
          <Grid container pt={6} px={3} alignItems={'center'} justifyContent={"center"}>
            <Grid item xs={12} md={4} />
            <Grid item xs={12} md={4}>
              <Typography
                align="center"
                sx={{
                  fontFamily: "Ubuntu",
                  fontStyle: "italic",
                  fontSize: "18px",
                  color: "rgba(255, 255, 255, 0.6);",
                }}
              >
                El Proyecto Vida Digna Sin Violencia de Solidar Suiza es implementado en Bolivia con apoyo de
                la Embajada de Suecia
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} />
          </Grid>
        </Box>
      </StyledBox>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          margin: 0,
          padding: 0,
          width: "100%",
          maxHeight: "110px",
          height: "100px",
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            maxWidth: "100%",
            width: { xs: "100%", md: "75%" },
            height: "100%",
            margin: 0,
            padding: 0,
            maxHeight: "110px",
          }}
        >
          <StyledImg  sx={{ height: "80%", mt: "0.8em" }} src={solidarLogo} alt="Imagen 3" />
          <StyledImg  sx={{ mt: "0.4em" }} src={marcaSwe} alt="Imagen 1" />
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
