import {
  ArrowBackRounded,
  ArrowForwardRounded,
  KeyboardBackspace,
} from "@mui/icons-material";
import {
  Box,
  Button,
  MobileStepper,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { convertFromRaw, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { printLog, isJsonString } from "../../../shared/utils";
import { ContentViewer } from "../../../shared/components/curso/content/ContentViewer";
import { YoutubePlayer } from "../../../shared/components/curso/YoutubePlayer";
import { QuizViewer } from "../../../shared/components/curso/QuizViewer";
import { CourseNeutralButton } from "../shared/Elements";
import { InstanciaUnidadCursoContenido, Unidad } from "../interface";
import { QuizFinishedEvent } from "../../../shared/components/curso/CursoViewerTypes";
import { getIconSrc } from "../student/components";

type UnidadVistaPreviaProps = {
  unidad: Unidad;
  nroUnidad: number | null;
  onClose: () => void;
};

export const UnidadVistaPrevia = ({
  unidad,
  onClose,
  nroUnidad,
}: UnidadVistaPreviaProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [activeStep, setActiveStep] = useState(0);
  const [contenidoUnidad, setContenidoUnidad] =
    useState<InstanciaUnidadCursoContenido | null>(null);

  const maxSteps = unidad !== null ? unidad.contenido.length : 0;
  printLog("maxSteps :: ", maxSteps);

  useEffect(() => {
    setContenidoUnidad(unidad.contenido[activeStep]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  const handleBack = async () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setContenidoUnidad(unidad.contenido[activeStep - 1]);
  };

  const convertedContent = (texto: string) => {
    printLog("Texto a convertir en html :: ", texto);
    if (isJsonString(texto) && JSON.parse(texto)) {
      const parseado = JSON.parse(texto);
      const editorContent = EditorState.createWithContent(
        convertFromRaw(parseado)
      );
      const rawContentState = convertToRaw(editorContent.getCurrentContent());
      let markup = draftToHtml(rawContentState);
      // Validando estilos con ancho y alto con valor auto
      markup = markup.replace(/(width\s*:\s*auto)/gi, "width: 100%");
      // markup = markup.replace(/(height\s*:\s*auto)/ig, "height: auto");
      // printLog('markup resultado :: ', markup)
      // setConvertedContent(convertidoHTML)
      return markup;
    } else {
      if (texto.trim() !== "") {
        return texto;
      }
      return "<p>Sin contenido</p>";
    }
  };
  const handleFinalizarQuiz = (data: QuizFinishedEvent) => {
    // printLog('finalizando quiz ::', data)
    return;
  };

  const handleNext = async () => {
    if (activeStep < maxSteps) {
      const siguientePaso = activeStep + 1;
      setActiveStep(siguientePaso);
      setContenidoUnidad(unidad.contenido[siguientePaso]);
    }
  };

  return (
    <Box
      sx={{
        backgroundImage:
          "radial-gradient(circle at 44% 9%, hsla(153,0%,47%,0.05) 0%, hsla(153,0%,47%,0.05) 74%,transparent 74%, transparent 83%,transparent 83%, transparent 100%),radial-gradient(circle at 0% 23%, hsla(153,0%,47%,0.05) 0%, hsla(153,0%,47%,0.05) 8%,transparent 8%, transparent 69%,transparent 69%, transparent 100%),radial-gradient(circle at 87% 35%, hsla(153,0%,47%,0.05) 0%, hsla(153,0%,47%,0.05) 10%,transparent 10%, transparent 61%,transparent 61%, transparent 100%),radial-gradient(circle at 22% 7%, hsla(153,0%,47%,0.05) 0%, hsla(153,0%,47%,0.05) 16%,transparent 16%, transparent 88%,transparent 88%, transparent 100%),radial-gradient(circle at 37% 26%, hsla(153,0%,47%,0.05) 0%, hsla(153,0%,47%,0.05) 7%,transparent 7%, transparent 9%,transparent 9%, transparent 100%),radial-gradient(circle at 86% 59%, hsla(153,0%,47%,0.05) 0%, hsla(153,0%,47%,0.05) 77%,transparent 77%, transparent 95%,transparent 95%, transparent 100%),radial-gradient(circle at 75% 83%, hsla(153,0%,47%,0.05) 0%, hsla(153,0%,47%,0.05) 35%,transparent 35%, transparent 86%,transparent 86%, transparent 100%),linear-gradient(0deg, hsl(279,0%,100%),hsl(279,0%,100%))",
      }}
    >
      <Box display="flex" my={2} alignItems="center">
        <CourseNeutralButton onClick={onClose}>
          <KeyboardBackspace />
        </CourseNeutralButton>
        {unidad !== null && (
          <Typography
            sx={{
              flex: 1,
              fontSize: "1.3rem",
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            {nroUnidad === 0 ? (
              <>{unidad.titulo}</>
            ) : (
              <>
                Unidad {Number(nroUnidad || 0)}: {unidad?.titulo}
              </>
            )}
          </Typography>
        )}
      </Box>
      {unidad !== null &&
        unidad.contenido !== null &&
        unidad.contenido.length > 0 && (
          <Box>
            {isMobile ? (
              <MobileStepper
                variant="progress"
                steps={unidad.contenido.length}
                position="static"
                activeStep={activeStep - 1}
                sx={{
                  flexGrow: 1,
                  justifyContent: "center",
                  background: "transparent",
                }}
                nextButton={null}
                backButton={null}
              />
            ) : (
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                style={{
                  transform: "scale(0.9)",
                }}
              >
                {unidad.contenido.map((label, index) => (
                  <Step key={label.titulo}>
                    <StepLabel
                      sx={{
                        "& .MuiStepIcon-root": {
                          color:
                            index <= activeStep
                              ? theme.palette.primary.main
                              : theme.palette.grey[500],
                        },
                        "& .MuiStepIcon-text": {
                          fill:
                            index === activeStep
                              ? theme.palette.getContrastText(
                                  theme.palette.primary.main
                                )
                              : theme.palette.getContrastText(
                                  theme.palette.grey[500]
                                ),
                        },
                      }}
                    >
                      {""}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            )}
            <Box sx={{ mt: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "calc(100vh - 290px)",
                  ml: {
                    xs: 1,
                    sm: 2,
                  },
                  mr: {
                    xs: 1,
                    sm: 2,
                  },
                  mb: {
                    xs: 1,
                    sm: 1,
                    md: 0,
                    lg: 0,
                  },
                }}
              >
                <Box
                  flex="1"
                  display="flex"
                  flexDirection="column"
                  alignItems="stretch"
                  sx={{
                    px: 1,
                    py: 2,
                    overflowY: "scroll",
                    border: `1px solid ${theme.palette.neutral.main}`,
                    borderRadius: "7px",
                    width: {
                      xs: "100%",
                      sm: "100%",
                      md: "calc(100% - 350px)",
                      lg: "calc(100% - 450px)",
                      xl: "calc(100% - 500px)",
                    },
                    [theme.breakpoints.down("md")]: {
                      px: 1,
                    },
                    "&::-webkit-scrollbar": {
                      width: "3px",
                      borderRadius: "10px",
                    },
                    "&::-webkit-scrollbar-track": {
                      background: theme.palette.neutral.light,
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: theme.palette.primary.main,
                    },
                  }}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 1,
                      fontWeight: 600,
                    }}
                  >
                    {contenidoUnidad !== null && contenidoUnidad ? (
                      <>
                        <Typography
                          component="div"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mb: 1,
                            fontWeight: 600,
                            color: '#ac0505'
                          }}
                        >
                          <Typography
                            component="span"
                            sx={{
                              color: theme.palette.grey[500],
                              fontWeight: 500,
                              display: "flex",
                              alignItems: "center",
                              marginRight: 1,
                            }}
                          >
                            <img
                              src={getIconSrc(contenidoUnidad.tipo)}
                              style={{ height: 23 }}
                              alt={`icono-${contenidoUnidad.tipo.toLowerCase()}`}
                            />
                          </Typography>
                          {contenidoUnidad.titulo}
                        </Typography>
                      </>
                    ) : (
                      ""
                    )}
                  </Typography>

                  <Box sx={{ height: "100%" }}>
                    {/* { JSON.stringify(unidadCurso.contenidos[activeStep - 1]) ?? '' } */}
                    {/* { JSON.stringify(contenidoUnidad) ?? '' } */}
                    {contenidoUnidad !== null &&
                      contenidoUnidad.tipo === "TEXTO" &&
                      contenidoUnidad.texto !== null && (
                        <ContentViewer
                          textoHTML={convertedContent(contenidoUnidad.texto)}
                        />
                      )}
                    {contenidoUnidad !== null &&
                      contenidoUnidad.tipo === "VIDEO" && (
                        <YoutubePlayer urlVideo={contenidoUnidad.link!} />
                      )}
                    {contenidoUnidad != null &&
                      contenidoUnidad.tipo === "QUIZ" && (
                        <>
                          <QuizViewer
                            idxContenido={activeStep}
                            tipo={contenidoUnidad.tipo}
                            isEvaluated={contenidoUnidad.isEvaluated}
                            preguntas={contenidoUnidad.preguntas}
                            onFinalizarQuiz={handleFinalizarQuiz}
                            tituloPrimero={false}
                            actionsPosition="static"
                            onCancel={() => {
                              activeStep <= 0 ? onClose() : handleBack();
                            }}
                          />
                        </>
                      )}
                    {contenidoUnidad !== null &&
                      contenidoUnidad.tipo === "ENTREGABLE" && (
                        <>
                          <QuizViewer
                            idxContenido={activeStep}
                            tipo={contenidoUnidad.tipo}
                            preguntas={contenidoUnidad.preguntas}
                            onFinalizarQuiz={handleFinalizarQuiz}
                          />
                        </>
                      )}

                    {contenidoUnidad !== null &&
                      contenidoUnidad.tipo === "HERRAMIENTAS" &&
                      contenidoUnidad.texto !== null && (
                        <ContentViewer
                          textoHTML={convertedContent(contenidoUnidad.texto)}
                        />
                      )}
                  </Box>
                </Box>
              </Box>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box
                  component={Button}
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{
                    background:
                      "linear-gradient(180deg, rgba(0,191,232,1) 0%, rgba(0,154,219,1) 53%, rgba(0,119,170,1) 100%)",
                    color: "white",
                    borderRadius: "8px",
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    "&:disabled": {
                      background: "transparent",
                      border: `1px solid ${theme.palette.neutral.main}`,
                    },
                    [theme.breakpoints.down("md")]: {
                      py: 1.5,
                      pl: 0.5,
                      pr: 0,
                    },
                  }}
                >
                  <ArrowBackRounded />
                </Box>
                <Box
                  component={Button}
                  disabled={activeStep === maxSteps - 1}
                  onClick={handleNext}
                  sx={{
                    background:
                      "linear-gradient(180deg, rgba(0,191,232,1) 0%, rgba(0,154,219,1) 53%, rgba(0,119,170,1) 100%)",
                    color: "white",
                    borderRadius: "8px",
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    "&:disabled": {
                      background: "transparent",
                      border: `1px solid ${theme.palette.neutral.main}`,
                    },
                    [theme.breakpoints.down("md")]: {
                      py: 1.5,
                      pl: 0.5,
                      pr: 0,
                    },
                  }}
                >
                  <ArrowForwardRounded />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
    </Box>
  );
};
