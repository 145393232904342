import contenidoTextoIcon from "../../../../assets/course/cm_doc_normal.svg";
import contenidoVideoIcon from "../../../../assets/course/cm_vid_normal.svg";
import contenidoQuizIcon from "../../../../assets/course/cm_tarea_normal.svg";
import contenidoEntregableIcon from "../../../../assets/course/cm_adj_up_normal.svg";
import contenidoToolsIcon from "../../../../assets/course/cm_tools.svg";

export const getIconSrc = (
  tipo: "TEXTO" | "ENTREGABLE" | "VIDEO" | "QUIZ" | "HERRAMIENTAS" | ""
) => {
  switch (tipo) {
    case "TEXTO":
      return contenidoTextoIcon;
    case "VIDEO":
      return contenidoVideoIcon;
    case "QUIZ":
      return contenidoQuizIcon;
    case "ENTREGABLE":
      return contenidoEntregableIcon;
    case "HERRAMIENTAS":
      return contenidoToolsIcon;
    default:
      return "";
  }
};
