import {
  ArrowBackRounded,
  ArrowForwardRounded,
  CancelRounded,
} from "@mui/icons-material";
import GaugeChart from "react-gauge-chart";
import {
  alpha,
  Box,
  Button,
  DialogContent,
  Grid,
  IconButton,
  MobileStepper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { TipoPregunta } from "../../../views/Courses/interface";
import { printLog } from "../../utils";
import theme from "../../../themes";
import { Alerts } from "../../hooks/useAlert";
import {
  AdjuntoMetadata,
  AdjuntosMap,
  QuizFinishedEvent,
  RespuestasMap,
} from "./CursoViewerTypes";
import { colorsBase } from "../../../themes/theme";
import { CoursePrimaryButton } from "../../../views/Courses/shared/Elements";
import CLoader from "../CLoader";
import { ResultadoMachometro } from "../../common/constants";

type QuizViewerSectionProps = {
  idxContenido?: number;
  tituloQuiz: string;
  tipo: "QUIZ" | "ENTREGABLE";
  preguntas: any[];
  onFinalizarQuiz: (data: QuizFinishedEvent) => void;
  actionsPosition?: "bottom" | "top" | "static";
  isEvaluated?: boolean;
  loading: boolean;
  withClose?: boolean;
  onCancel?: () => void;
  resetOnComplete?: boolean;
  result?: number | null;
};

const classes = {
  btnQuiz: {
    background: alpha(theme.palette.courseSecondary.main, 0.15),
    color: theme.palette.courseSecondary.main,
    fontWeight: 700,
    borderRadius: "8px",
    px: 2,
    py: 2,
    "@media (maxWidth:899.95px)": {
      p: 2,
    },
  },
  btnQuizSelected: {
    background:
      "linear-gradient(180deg, rgba(203,42,182,1) 0%, rgba(149,29,145,1) 53%, rgba(96,15,93,1) 100%)",
    color: "#fff",
    fontWeight: 700,
    borderRadius: "8px",
    px: 2,
    py: 2,
    "@media (maxWidth:899.95px)": {
      p: 2,
    },
  },
};

export const QuizViewerSection = ({
  idxContenido,
  tituloQuiz,
  tipo,
  isEvaluated,
  preguntas,
  loading,
  onFinalizarQuiz,
  actionsPosition = "bottom",
  onCancel = () => {},
  withClose = true,
  resetOnComplete = false,
  result,
}: QuizViewerSectionProps) => {
  const theme = useTheme();
  const matchMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // printLog("is mobile :: ", matchMobile);
  const [activeStep, setActiveStep] = useState(0);
  const [respuestas, setRespuestas] = useState<RespuestasMap>({});
  // TODO: pendiente errores en quiz
  // const [errores, setErrores] = useState<any>({});
  const [adjuntosRespuestas, setAdjuntosRespuestas] = useState<AdjuntosMap>({});

  const [rowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [finalizado, setFinalizado] = useState(false);
  const maxSteps = Math.floor(preguntas.length / rowsPerPage) + 1;

  /// Context de alertas
  // const alertContext = useAlertModalContext();

  useEffect(() => {
    if (tipo === "QUIZ" && isEvaluated) {
      Alerts.showConfirm({
        title: "¡Estás a punto de demostrar todo lo que has aprendido!",
        description:
          "El siguiente quiz está sujeto a evaluación, se requiere que el 80% de las preguntas estén correctas para avanzar." +
          " Presiona confirmar para empezar.",
        onConfirm: () => {},
        onCancel,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    printLog(">>> preguntas ::: ", preguntas);
    const _respuestas: RespuestasMap = {};
    preguntas.forEach((pregunta: any, idx: number) => {
      _respuestas[idx] = "";
      if (pregunta.tipo === TipoPregunta.SELECCION_MULTIPLE)
        _respuestas[idx] = [];
      if (
        pregunta.tipo === TipoPregunta.CARGA_ARCHIVO ||
        pregunta.tipo === TipoPregunta.CARGA_VIDEO
      ) {
        if (pregunta.accionPermitida === "ENLACES") _respuestas[idx] = [""];
        if (pregunta.accionPermitida === "ARCHIVOS") _respuestas[idx] = [];
      }
    });
    setRespuestas(_respuestas);
    setActiveStep(0);
  }, [preguntas]);

  const handleSiguientePregunta = async () => {
    // if (tipo === "ENTREGABLE") {
    //   /// evaluar pregunta enlaces
    //   switch (preguntas[activeStep].accionPermitida) {
    //     case "ENLACES":
    //       const erroresEnlaces: number[] = [];

    //       const respuesta = respuestas[activeStep] as string[];
    //       respuesta.forEach((_enlace: string, idx: number) => {
    //         if (!isValidUrl(_enlace)) {
    //           erroresEnlaces.push(idx + 1);
    //         }
    //       });
    //       if (erroresEnlaces.length) {
    //         Alerts.showError({
    //           title: "Enlaces inválidos",
    //           description: `${
    //             erroresEnlaces.length > 1 ? "Los enlaces" : "El enlace"
    //           }  ${erroresEnlaces.join("-")} ${
    //             erroresEnlaces.length > 1 ? " no son válidos" : " no es válido."
    //           }`,
    //         });
    //         // await alertContext.showErrorAlert({
    //         //   btnText: "Continuar",
    //         //   title: "Enlaces inválidos",
    //         //   message: `${
    //         //     erroresEnlaces.length > 1 ? "Los enlaces" : "El enlace"
    //         //   }  ${erroresEnlaces.join("-")} ${
    //         //     erroresEnlaces.length > 1 ? " no son válidos" : " no es válido."
    //         //   }`,
    //         // })
    //         return;
    //       }
    //       break;
    //   }
    // }

    printLog("VALIDAR FORMULARIO >>>", respuestas);
    const subGrupoPreguntas = [
      ...preguntas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    ];
    printLog(
      "Subgrupo de preguntas >>> cantidad >>> ",
      subGrupoPreguntas.length
    );
    const faltantes = [];
    for (const _pregunta of subGrupoPreguntas) {
      printLog("validando....", respuestas[_pregunta.indice]);
      if (
        respuestas[_pregunta.indice].toString() !== "0" &&
        respuestas[_pregunta.indice].toString() !== "1"
      ) {
        faltantes.push(_pregunta.indice + 1);
      }
    }
    if (faltantes.length) {
      printLog("Faltan responder >>> ", faltantes);
      Alerts.showError({
        description: `Faltan responder las siguientes preguntas: ${faltantes.join(
          ","
        )}`,
      });
      return;
    }

    if (activeStep === maxSteps - 1) {
      Alerts.showConfirm({
        title:
          tipo === "QUIZ"
            ? "¿Desea finalizar el quiz?"
            : "¿Desea finalizar la entrega del desafío?",
        description:
          "Tenga en cuenta que no podrá modificar sus respuestas luego de confirmar.",
        onConfirm: () => {
          printLog("idxContenido", idxContenido);
          printLog("Finalizar el quiz", respuestas);
          printLog("Finalizar el quiz", JSON.stringify(respuestas));
          // printLog("Adjuntos de respuestas ??? ", adjuntosRespuestas);
          onFinalizarQuiz({
            idxContenido: idxContenido!,
            estado: "FINALIZADO",
            respuestas,
            adjuntosRespuestas,
          });
          setFinalizado(true);
        },
      });
      // const confirmation = await alertContext.showConfirmation({
      //   title:
      //     tipo === "QUIZ"
      //       ? "¿Desea finalizar el quiz?"
      //       : "¿Desea finalizar el contenido entregable?",
      //   message:
      //     "Tenga en cuenta que no podrá modificar sus respuestas luego de confirmar.",
      //   })
      // if (confirmation) {
      //     printLog("Finalizar el quiz", respuestas);
      //     printLog("Adjuntos de respuestas ??? ", adjuntosRespuestas);
      //     onFinalizarQuiz({
      //       idxContenido,
      //       estado: "FINALIZADO",
      //       respuestas,
      //       adjuntosRespuestas,
      //     });
      // }
    } else {
      printLog("page >>> ", page);
      setPage((prevPage) => prevPage + 1);
      myRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleAnteriorPregunta = () => {
    printLog("page >>> ", page);
    setPage((prevPage) => prevPage - 1);
    myRef.current?.scrollIntoView();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const setRespuestaUnica = (idxRespuesta: number, valor: string) => {
    const tmpRespuestas = { ...respuestas };
    tmpRespuestas[idxRespuesta] = valor;
    setRespuestas(tmpRespuestas);
  };

  const setRespuestaMultiple = (idxRespuesta: number, valor: string) => {
    const tmpRespuestas = { ...respuestas };
    const respuestaMultiple = tmpRespuestas[idxRespuesta] as string[];
    if (respuestaMultiple.includes(valor)) {
      const idxValor = respuestaMultiple.indexOf(valor);
      respuestaMultiple.splice(idxValor, 1);
    } else {
      respuestaMultiple.push(valor);
    }

    printLog("respuestas despues :: ", tmpRespuestas);
    printLog("respuestas despues :: ", JSON.stringify(tmpRespuestas));
    setRespuestas(tmpRespuestas);
  };

  const handleInputChange =
    (idxRespuesta: number) =>
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const tmpRespuestas = { ...respuestas };
      tmpRespuestas[idxRespuesta] = target.value;
      setRespuestas(tmpRespuestas);
    };

  const handleInputEnlaceChange =
    (idxRespuesta: number, idxEnlace: number) =>
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const tmpRespuestas = { ...respuestas };
      const respuesta = tmpRespuestas[idxRespuesta] as string[];
      printLog("evaluando enlace.....");
      respuesta[idxEnlace] = target.value;
      setRespuestas(tmpRespuestas);
    };

  const handleFileChange =
    (idxRespuesta: number) =>
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const tmpRespuestas = { ...respuestas };
      let respuesta = tmpRespuestas[idxRespuesta] as AdjuntoMetadata[];
      const respuestaID = uuidv4();
      respuesta = [
        ...respuesta,
        {
          tempId: respuestaID,
          nombreArchivo: target.files![0].name,
        },
        // ...target.files!
      ];
      setRespuestas(tmpRespuestas);
      setAdjuntosRespuestas({
        ...adjuntosRespuestas,
        [respuestaID]: target.files![0],
      });
    };

  const handleDeleteFile = (
    idxRespuesta: number,
    idxAdjunto: number,
    adjunto: any
  ) => {
    const tmpRespuestas = { ...respuestas };
    let respuesta = tmpRespuestas[idxRespuesta] as AdjuntoMetadata[];
    respuesta.splice(idxAdjunto, 1);
    setRespuestas(tmpRespuestas);
    delete adjuntosRespuestas[adjunto.tempId];
    setAdjuntosRespuestas({ ...adjuntosRespuestas });
  };

  const añadirEnlaceRespuesta = (idxRespuesta: number) => {
    const tmpRespuestas = { ...respuestas };
    const respuestaEnlaces = tmpRespuestas[idxRespuesta] as string[];
    respuestaEnlaces.push("");
    setRespuestas(tmpRespuestas);
  };

  const eliminarEnlaceRespuesta = (idxRespuesta: number, idxEnlace: number) => {
    const tmpRespuestas = { ...respuestas };
    const respuesta = tmpRespuestas[idxRespuesta] as AdjuntoMetadata[];
    respuesta.splice(idxEnlace, 1);
    setRespuestas(tmpRespuestas);
  };

  const downloadAdjunto = (ev: any, _adjunto: any) => {
    ev.stopPropagation();
    printLog("descargar adjunto :: ", _adjunto);
    const link = document.createElement("a");
    const chunks = _adjunto.split("/");
    link.href = _adjunto;
    link.download = chunks[chunks.length - 1]; // nombre del archivo?
    link.target = "_blank";
    link.click();
  };

  const myRef = useRef<HTMLDivElement>(null);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignContent="center"
      justifyItems="center"
      alignItems="stretch"
      sx={{ position: "relative" }}
    >
      <div ref={myRef} />
      <Box
        mb={1}
        pt={2}
        sx={{
          position: "sticky",
          top: 0,
          left: 0,
          right: 0,
          background: "#fff",
          pb: 1,
          px: 1.5,
          borderBottom: "1px solid #c0c0c0",
        }}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Typography
          sx={{
            flexGrow: 1,
            fontSize: "1.8rem",
            fontWeight: "700",
            textAlign: "center",
            textTransform: "uppercase",
            color: colorsBase.Naranja2,
          }}
        >
          {tituloQuiz}
        </Typography>
        {withClose && (
          <IconButton onClick={onCancel}>
            <CancelRounded />
          </IconButton>
        )}
      </Box>
      {!finalizado && (
        <Box>
          <Box display={"flex"} flexDirection={"column"} gap={3} p={3}>
            {preguntas
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((pregunta, idxPregunta: number) => (
                <Box
                  key={`${idxPregunta + page * rowsPerPage}-pregunta`}
                  flexGrow="1"
                  display="flex"
                  flexDirection="column"
                >
                  <Typography
                    paragraph
                    sx={{
                      wordBreak: "break-word",
                      fontSize: "1.1rem",
                      fontWeight: "600",
                    }}
                  >
                    {pregunta.indice + 1}.- {pregunta.titulo}
                  </Typography>
                  <Typography
                    sx={{
                      color: "black",
                      wordBreak: "break-word",
                      textAlign: "justify",
                    }}
                  >
                    {pregunta.descripcion}
                  </Typography>
                  {pregunta.tipo === TipoPregunta.SELECCION_UNICA && (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Box
                            sx={{
                              ...classes.btnQuiz,
                              ...(respuestas[
                                idxPregunta + page * rowsPerPage
                              ] != null &&
                                respuestas[idxPregunta + page * rowsPerPage] ===
                                  pregunta.opcion1.texto &&
                                classes.btnQuizSelected),
                              width: "100%",
                              wordBreak: "break-word",
                            }}
                            onClick={() =>
                              setRespuestaUnica(
                                idxPregunta + page * rowsPerPage,
                                pregunta.opcion1.texto
                              )
                            }
                            display="flex"
                            alignItems="center"
                            justifyContent={"space-between"}
                          >
                            {pregunta.opcion1.label}
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Box
                            sx={{
                              ...classes.btnQuiz,
                              ...(respuestas[
                                idxPregunta + page * rowsPerPage
                              ] != null &&
                                respuestas[idxPregunta + page * rowsPerPage] ===
                                  pregunta.opcion2.texto &&
                                classes.btnQuizSelected),
                              width: "100%",
                              wordBreak: "break-word",
                            }}
                            onClick={() =>
                              setRespuestaUnica(
                                idxPregunta + page * rowsPerPage,
                                pregunta.opcion2.texto
                              )
                            }
                            display="flex"
                            alignItems="center"
                            justifyContent={"space-between"}
                          >
                            {pregunta.opcion2.label}
                          </Box>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Box>
              ))}
          </Box>
          <MobileStepper
            variant="text"
            steps={maxSteps}
            position={actionsPosition}
            activeStep={activeStep}
            backButton={
              <Box
                component={Button}
                size={matchMobile ? "small" : "medium"}
                disabled={activeStep === 0}
                sx={{
                  background:
                    "linear-gradient(180deg, rgba(0,191,232,1) 0%, rgba(0,154,219,1) 53%, rgba(0,119,170,1) 100%)",
                  color: "white",
                  borderRadius: "8px",
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  "&:disabled": {
                    background: "transparent",
                    border: `1px solid ${theme.palette.neutral.main}`,
                  },
                  [theme.breakpoints.down("md")]: {
                    py: 1.5,
                    pl: 0.5,
                    pr: 0,
                  },
                }}
                onClick={handleAnteriorPregunta}
              >
                <ArrowBackRounded />
              </Box>
            }
            nextButton={
              <Box
                component={Button}
                size={matchMobile ? "small" : "medium"}
                sx={{
                  background:
                    "linear-gradient(180deg, rgba(0,191,232,1) 0%, rgba(0,154,219,1) 53%, rgba(0,119,170,1) 100%)",
                  color: "white",
                  borderRadius: "8px",
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  "&:disabled": {
                    background: "transparent",
                    border: `1px solid ${theme.palette.neutral.main}`,
                  },
                  [theme.breakpoints.down("md")]: {
                    py: 1.5,
                    pl: 0.5,
                    pr: 0,
                  },
                }}
                onClick={handleSiguientePregunta}
              >
                <ArrowForwardRounded />
              </Box>
            }
            sx={{
              textAlign: "center",
              fontSize: "1.3rem",
              fontWeight: 800,
              px: 0,
              backgroundColor: alpha("#fff", 0.6),
              backdropFilter: "blur(5px)",
              color: theme.palette.info.hover,
              position: "sticky",
              bottom: 0,
              left: 0,
              right: 0,
            }}
          ></MobileStepper>
        </Box>
      )}
      {finalizado && loading && <CLoader message={"Calculando tu puntaje"} />}
      {finalizado && !loading && (
        <>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={3}
            p={3}
            alignItems={"center"}
          >
            <Typography fontWeight={"medium"} fontSize={"1.2rem"}>
              ¡Gracias por participar en el test!
            </Typography>

            <Box my={1}>
              <GaugeChart
                id="gauge-chart-result"
                nrOfLevels={9}
                // arcsLength={[0.33, 0.34, 0.33]}
                colors={["#5BE12C", "#F5CD19", "#EA4228"]}
                textColor={"#000000"}
                percent={(result ?? 0) / 100}
                // needleColor={"#5392ff"}
                needleColor={colorsBase.Naranja2}
                arcPadding={0.02}
                // arcWidth={0.5}
                marginInPercent={0.01}
                fontSize="28"
                style={{
                  fontWeight: "bold",
                  padding: 15,
                  // border: "1px solid red",
                }}
                // formatTextValue={(value) => value}
              />
            </Box>

            <Box>
              <Typography component="span" fontSize={"1.1rem"}>
                Tu nivel de machismo es:{" "}
              </Typography>
              <Typography
                component="span"
                fontSize={"1.2rem"}
                fontWeight={"bold"}
              >
                {getNivelMachismo(result)}
              </Typography>
            </Box>
            <Box>
              <CoursePrimaryButton
                onClick={() => {
                  if (resetOnComplete) {
                    setFinalizado(false);
                    setActiveStep(0);
                    setPage(0);
                  }
                  onCancel();
                }}
              >
                Aceptar
              </CoursePrimaryButton>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

function ListaPreguntas({
  preguntas,
  page,
  rowsPerPage,
}: {
  preguntas: any[];
  page: number;
  rowsPerPage: number;
}) {
  return <DialogContent></DialogContent>;
}

function getNivelMachismo(result?: number | null): string {
  printLog("[getNivelMachismo] Resultado > ", result);
  if (!result && result !== 0) return "...";
  if (result <= 28) return ResultadoMachometro.NADA_MACHISTA;
  if (result <= 52) return ResultadoMachometro.POCO_MACHISTA;
  if (result <= 76) return ResultadoMachometro.MACHISTA;

  return ResultadoMachometro.MUY_MACHISTA;
}
